*:focus-visible,
*:focus {
  outline: unset !important;
  box-shadow: unset !important;
}
*:not(.flatpickr-input)[readonly] {
  background-color: rgba(216, 216, 216, 0.5) !important;
}
body {
  background-color: #f7f7f7;
  background-image: url("/public/backgroundimage.png");
  background-size: cover;
}
.rmdp-container {
  display: block !important;
}
* {
  font-family: "Sora", sans-serif;
}

.some-custom-class {
  z-index: 2222 !important;
}
